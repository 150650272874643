export default {
  blogerName: 'NAOKITV',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/naokitv7',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@NAOKINVEST',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@NAOKIFC',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/cf4b527d7',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c2ed764d4',
      gameTitle: 'Starda Queen (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c5683ddc9',
      gameTitle: 'Alien Fruits (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cd745ae9b',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c8900ff73',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cfce42d92',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ce20df867',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cfd8f7a7c',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>NAOKITV</strong> e receba 125 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'NAOKITV',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>125FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
